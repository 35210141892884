import React, { useEffect, useState } from 'react';
import Section from 'components/Section';
import Link from 'next/link';
import Image from 'next/image';
import ItemSite from './ItemSite';
import Testamonials from './testamonials';
import {
    motion,
    useViewportScroll,
    useTransform,
    AnimatePresence
} from 'framer-motion';

const DownIcon = () => {
    return (
        <svg
            height="80"
            width="80"
            fill="none"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            style={{ margin: 'auto' }}
        >
            <path
                d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 7C11 6.44772 10.5523 6 10 6C9.44771 6 9 6.44772 9 7L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L9.29289 13.7071C9.68342 14.0976 10.3166 14.0976 10.7071 13.7071L13.7071 10.7071C14.0976 10.3166 14.0976 9.68342 13.7071 9.29289C13.3166 8.90237 12.6834 8.90237 12.2929 9.29289L11 10.5858V7Z"
                fill="#41E18B"
                fillRule="evenodd"
            />
        </svg>
    );
};

const Chevron = ({ darkmode }) => {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ margin: '0px', padding: '0px' }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.29289 14.7071C6.90237 14.3166 6.90237 13.6834 7.29289 13.2929L10.5858 10L7.29289 6.70711C6.90237 6.31658 6.90237 5.68342 7.29289 5.29289C7.68342 4.90237 8.31658 4.90237 8.70711 5.29289L12.7071 9.29289C13.0976 9.68342 13.0976 10.3166 12.7071 10.7071L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071Z"
                fill={darkmode ? '#AEB5BF' : '#4A5568'}
            />
        </svg>
    );
};

const SavedSite = ({ src, title, url, top }) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                position: 'absolute',
                top: top,
                left: '-54px'
            }}
        >
            <div
                style={{
                    background: '#FFF',
                    boxShadow: '0 1px 6px -1px rgba(78,80,81,0.40)',
                    height: '28px',
                    width: '28px',
                    borderRadius: '7px',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <div style={{ margin: 'auto', display: 'flex' }}>
                    <Image
                        src={src}
                        alt={title + ' favIcon'}
                        height={20}
                        width={20}
                    />
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '16px'
                }}
            >
                <p style={{ fontSize: '13px', fontWeight: '600' }}>{title}</p>
                <p
                    style={{
                        fontSize: '11px',
                        fontWeight: '500',
                        color: '#A5ADB9'
                    }}
                >
                    {url}
                </p>
            </div>
        </div>
    );
};

const Avatar = ({ quote, name, title, url, pic, blue }) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                padding: '36px 32px',
                border: blue
                    ? '1px dashed rgb(223, 237, 255, 0.6)'
                    : '1px dashed #C3C9D2',
                borderRadius: '12px',
                position: 'relative',
                marginRight: '36px'
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    width: '5px',
                    height: '43px',
                    background: blue ? 'rgb(0, 120, 255)' : '#46DD89',
                    top: '40px',
                    left: '-3px',
                    borderRadius: '4px'
                }}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <h3
                    className={blue && 'isFFF'}
                    style={{
                        fontFamily: 'Rubik, sans-serif',
                        fontSize: '18px',
                        fontWeight: '500',
                        marginBottom: '16px',
                        fontStyle: 'italic'
                    }}
                >
                    "{quote}"
                </h3>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Image
                        src={pic}
                        width={48}
                        height={48}
                        alt="founder picture"
                    />
                    <div style={{ marginLeft: '20px' }}>
                        <p
                            style={{
                                fontFamily: 'Rubik, sans-serif',
                                fontSize: '16px',
                                fontWeight: '500',
                                color: blue ? '#FFF' : '#04184a'
                            }}
                        >
                            {name}
                        </p>
                        <Link
                            href={url}
                            alt={url}
                            style={{
                                fontFamily: 'Rubik, sans-serif',
                                color: blue ? 'rgb(223, 237, 255)' : '#646F7D',
                                fontSize: '14px',
                                fontWeight: '400',
                                marginTop: '-2px'
                            }}
                        >
                            {title}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

const FeatureCards = ({ scrollYProgress, isMobileViewport }) => {
    const [showPopover, setShowPopover] = useState(false);
    //yRange = group fylld med länkar
    const yMin = isMobileViewport ? 20 : 220;
    const yMax = isMobileViewport ? -30 : -330;
    const yRange = useTransform(scrollYProgress, [0, 1], [yMin, yMax]);
    //xRange = youtube länk som ska droppas
    const xRange = useTransform(scrollYProgress, [0, 1], [-80, 160]);
    //pointerYX = pointer som hovrar openall
    const groupX = isMobileViewport ? '16%' : '34%';
    const pointerMin = isMobileViewport ? 200 : 250;
    const pointerGoal = isMobileViewport ? 0.165 : 0.335;
    const pointerY = useTransform(
        scrollYProgress,
        [0, pointerGoal],
        [pointerMin, 0]
    );
    useEffect(
        () => pointerY.onChange((v) => setShowPopover(v >= 1)),
        [pointerY]
    );

    const linksInCard = [
        {
            src: '/features/github_favicon.png',
            title: 'GitHub',
            url: 'github.com',
            top: '76px'
        },
        {
            src: '/features/gmail_favicon.png',
            title: 'Gmail Inbox',
            url: 'mail.google.com/mail/u/0/#inbox',
            top: '130px'
        },
        {
            src: '/features/twitter_favicon.png',
            title: 'Home / Twitter',
            url: 'www.twitter.com/home',
            top: '184px'
        },
        {
            src: '/features/docs_favicon.png',
            title: 'Google Docs',
            url: 'doc.new',
            top: '239px'
        }
    ];
    return (
        <div
            style={{
                margin: 'auto',
                height: '100%',
                width: '100%',
                position: 'relative',
                minHeight: '600px'
                //border: "1px solid gray",
            }}
        >
            <div
                className="dropzoneBox"
                style={{
                    height: '375px',
                    width: '300px',
                    position: 'absolute',
                    top: '8%',
                    display: 'flex'
                }}
            >
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            position: 'absolute',
                            height: '35px',
                            width: '35px',
                            borderRadius: '6px',
                            background: '#BEF8C9',
                            left: '24px',
                            top: '24px'
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'column',
                            top: '26px',
                            left: '69px'
                        }}
                    >
                        <div
                            style={{
                                height: '16px',
                                width: '80px',
                                borderRadius: '4px',
                                background: '#BEF8C9'
                            }}
                        />
                        <div
                            style={{
                                marginTop: '4px',
                                height: '10px',
                                width: '33px',
                                borderRadius: '3px',
                                background: '#BEF8C9'
                            }}
                        />
                    </div>
                </div>
                <DownIcon />
            </div>
            <motion.div
                style={{
                    x: xRange,
                    height: '38px',
                    width: '184px',
                    background: '#FFF',
                    boxShadow:
                        '0px 0px 0px 2px #44E18C, 0 6px 18px -2px rgba(152,169,185,0.60)',
                    borderRadius: '5px',
                    position: 'absolute',
                    top: '118px',
                    left: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '12px'
                    //border: "2px solid #41E18B",
                }}
            >
                <Image
                    src="/features/youtube_icon.svg"
                    height={22}
                    width={22}
                />
                <p
                    style={{
                        marginLeft: '12px',
                        fontWeight: '600',
                        fontSize: '14px'
                    }}
                >
                    YouTube
                </p>
            </motion.div>
            <motion.div
                style={{
                    position: 'absolute',
                    top: '139px',
                    left: '150px',
                    x: xRange
                }}
            >
                <Image src="/features/Closed.png" height={24} width={24} />
            </motion.div>
            <motion.div
                className="spaceCard"
                style={{
                    y: yRange,
                    height: '375px',
                    width: '300px',
                    position: 'absolute',
                    top: '30%',
                    left: groupX,
                    display: 'flex'
                }}
            >
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            position: 'absolute',
                            left: '20px',
                            top: '18px'
                        }}
                    >
                        <Image
                            src="/features/globe_emoji.png"
                            width={50}
                            height={67}
                        />
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'column',
                            top: '26px',
                            left: '80px'
                        }}
                    >
                        <p style={{ fontWeight: '700', fontSize: '17px' }}>
                            Quicklinks
                        </p>
                        <div
                            style={{
                                background: '#EDF2F7',
                                borderRadius: '4px',
                                padding: '2px 10px',
                                width: '76px',
                                letterSpacing: '0.30px',
                                fontWeight: '500',
                                fontSize: '12px',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <p
                                style={{
                                    marginRight: '2px',
                                    fontWeight: '600'
                                }}
                            >
                                4 sites
                            </p>
                            <Chevron />
                        </div>
                        {linksInCard.map((item, index) => (
                            <SavedSite
                                key={index}
                                top={item.top}
                                src={item.src}
                                title={item.title}
                                url={item.url}
                            />
                        ))}
                    </div>
                    {!showPopover && (
                        <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            style={{
                                transformOrigin: '50% 0%',
                                top: '82px',
                                left: '82px',
                                position: 'absolute',
                                padding: '4px 6px',
                                background: '#363D49',
                                borderRadius: '3px',
                                boxShadow: '0 2px 4px 0 rgba(0,0,0,0.30)'
                            }}
                        >
                            <p
                                style={{
                                    fontWeight: '500',
                                    color: '#FFF',
                                    fontSize: '14px'
                                }}
                            >
                                Open all
                            </p>
                        </motion.div>
                    )}
                    <motion.div
                        style={{
                            top: '64px',
                            left: '137px',
                            position: 'absolute',
                            y: pointerY
                        }}
                    >
                        <Image
                            src="/features/pointing.png"
                            height={24}
                            width={24}
                        />
                    </motion.div>
                </div>
            </motion.div>
            {/* <Image
                src="/features/drop_and_open.png"
                alt="Drop tab and open groups"
                width={479}
                height={518}
              /> */}
        </div>
    );
};

const FeatureNotes = ({ scrollYProgress, isMobileViewport }) => {
    const [showBox, setShowBox] = useState(false);
    const pointerGoal = isMobileViewport ? 0.315 : 0.475;
    const boxGoal = isMobileViewport ? 0.485 : 0.73;
    const bigBoxMin = isMobileViewport ? -270 : -100;
    const smallBoxMin = isMobileViewport ? -150 : -25;
    const bigBox = useTransform(scrollYProgress, [0, 0.75], [175, bigBoxMin]);
    const smallbox = useTransform(
        scrollYProgress,
        [0, 0.75],
        [50, smallBoxMin]
    );
    const smallbox1 = useTransform(scrollYProgress, [0, boxGoal], [50, -25]);
    const writePointerY = useTransform(
        scrollYProgress,
        [0, pointerGoal],
        [-500, 0]
    );
    useEffect(() => smallbox1.onChange((v) => setShowBox(v >= 1)), [smallbox1]);

    return (
        <div
            style={{
                margin: 'auto',
                height: '100%',
                minHeight: '600px',
                width: '100%',
                position: 'relative'
            }}
        >
            <motion.div
                className="PurpleCard"
                style={{ x: bigBox, rotate: '-10deg' }}
            />
            <motion.div
                className="PinkCard"
                style={{ x: smallbox, rotate: '-10deg' }}
            />
            <div
                className="spaceCard"
                style={{
                    height: '375px',
                    width: '300px',
                    position: 'absolute',
                    left: 'calc(50% - 150px)',
                    top: '26%',
                    display: 'flex'
                }}
            >
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            position: 'absolute',
                            left: '20px',
                            top: '18px'
                        }}
                    >
                        <Image
                            src="/features/notes_emoji.png"
                            width={50}
                            height={67}
                        />
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'column',
                            top: '26px',
                            left: '80px'
                        }}
                    >
                        <p style={{ fontWeight: '700', fontSize: '17px' }}>
                            Notes
                        </p>
                        <div
                            style={{
                                background: '#EDF2F7',
                                borderRadius: '4px',
                                padding: '2px 10px',
                                width: '67px',
                                letterSpacing: '0.30px',
                                fontWeight: '500',
                                fontSize: '12px',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <p
                                style={{
                                    marginRight: '2px',
                                    fontWeight: '600'
                                }}
                            >
                                1 site
                            </p>
                            <Chevron />
                        </div>
                        <SavedSite
                            src={'/features/youtube_favicon.png'}
                            title={'YouTube'}
                            url={'www.youtube.com/upload'}
                            top={'76px'}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                background: '#EEF0F4',
                                borderRadius: '5px',
                                top: '122px',
                                left: '-54px',
                                width: '244px',
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: '10px',
                                paddingTop: '8px',
                                paddingBottom: '10px'
                            }}
                        >
                            <p
                                style={{
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    lineHeight: '19px',
                                    letterSpacing: '0.24px'
                                }}
                            >
                                Upload video after page is done <br />• Create
                                intro <br />
                                • Links <br />• Design thumbnail
                            </p>
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                top: '228px',
                                left: '-54px',
                                width: '244px',
                                height: '34px',
                                display: 'flex',
                                alignItems: 'center'
                                //paddingTop: "8px",
                                //paddingBottom: "10px",
                            }}
                        >
                            <Image
                                src={'/features/checkdone.png'}
                                height={'34'}
                                width={'31'}
                                alt="Done checkmark"
                            />
                            <div
                                style={{
                                    background: '#EEF0F4',
                                    borderRadius: '5px',
                                    paddingLeft: '10px',
                                    width: '213px',
                                    height: '34px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        lineHeight: '19px',
                                        letterSpacing: '0.24px',
                                        textDecoration: 'line-through',
                                        opacity: '0.6'
                                    }}
                                >
                                    Write blog post
                                </p>
                            </div>
                        </div>
                        <AnimatePresence>
                            {!showBox && (
                                <motion.div
                                    initial={{
                                        scale: 0.5,
                                        boxShadow: '0px 0px 0px 0px #63B3ED',
                                        opacity: 1
                                    }}
                                    animate={{
                                        scale: 1,
                                        boxShadow: '0px 0px 0px 3px #63B3ED',
                                        opacity: 1
                                    }}
                                    exit={{
                                        scale: 0,
                                        opacity: 0,
                                        boxShadow: '0px 0px 0px 0px #63B3ED'
                                    }}
                                    transition={{
                                        type: 'spring',
                                        stiffness: 300,
                                        damping: 24,
                                        boxShadow: { delay: 0.3 }
                                    }}
                                    style={{
                                        transformOrigin: '100% 100%',
                                        position: 'absolute',
                                        top: '274px',
                                        left: '-54px',
                                        width: '244px',
                                        height: '34px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        background: '#EEF0F4',
                                        borderRadius: '5px'
                                    }}
                                >
                                    <motion.div
                                        initial={{ scaleY: 0 }}
                                        animate={{
                                            scaleY: 1,
                                            opacity: [1, 0, 1, 0]
                                        }}
                                        transition={{
                                            scaleY: { delay: 0.425 },
                                            opacity: {
                                                delay: 0.4,
                                                duration: 0.2,
                                                //ease: "easeInOut",
                                                times: [0.2, 0.4, 0.5, 0],
                                                yoyo: Infinity,
                                                repeatDelay: 0.375
                                            }
                                        }}
                                        style={{
                                            margin: 'auto',
                                            height: '17px',
                                            width: '1px',
                                            background: '#1F242E',
                                            marginLeft: '12px'
                                        }}
                                    />
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                    <motion.div
                        style={{
                            top: '311px',
                            left: '216px',
                            position: 'absolute',
                            y: writePointerY
                        }}
                    >
                        <Image
                            src="/features/writepointer.png"
                            height={18}
                            width={8}
                        />
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

const FeatureCloud = ({ scrollYProgress, isMobileViewport }) => {
    const [tickBox, setTickBox] = useState(false);
    const boxGoal = isMobileViewport ? 0.315 : 0.615;
    const boxCheck = isMobileViewport ? 0.49 : 0.625;
    const checkBoxStart = isMobileViewport ? 0.455 : 0.59;
    const checkBoxMiddle = isMobileViewport ? 0.48 : 0.615;
    const checkBoxEnd = isMobileViewport ? 0.49 : 0.625;
    const checkBoxColorStart = isMobileViewport ? 0.48 : 0.615;
    const checkBoxColorEnd = isMobileViewport ? 0.49 : 0.625;
    const smallCloudX = isMobileViewport ? -250 : -100;
    const smallCloud = useTransform(
        scrollYProgress,
        [0, 0.9],
        [250, smallCloudX]
    );
    const pointerY = useTransform(scrollYProgress, [0, boxCheck], [300, 0]);
    const checkBoxScale = useTransform(
        scrollYProgress,
        [checkBoxStart, checkBoxMiddle, checkBoxEnd],
        ['scale(1)', 'scale(1.2)', 'scale(0.94)']
    );
    const checkBoxColor = useTransform(
        scrollYProgress,
        [checkBoxColorStart, checkBoxColorEnd],
        ['2px solid #696a74', '2px solid #63B3ED']
    );

    useEffect(() => pointerY.onChange((v) => setTickBox(v >= 1)), [pointerY]);

    return (
        <div
            style={{
                margin: 'auto',
                height: '100%',
                minHeight: '600px',
                width: '100%',
                position: 'relative'
            }}
        >
            {/* <motion.div
        style={{
          x: smallCloud,
          position: "absolute",
          top: "calc(50% - 290px)",
          left: "calc(50% - -62px)",
          transform: "scale(1.35)",
        }}
      >
        <Image src={"/features/cloud.png"} width={168} height={126} alt="upload to cloud" />
      </motion.div> */}
            <div
                className="spaceCardDark"
                style={{
                    height: '375px',
                    width: '300px',
                    position: 'absolute',
                    left: 'calc(50% - 150px)',
                    top: 'calc(50% - 188px)',
                    display: 'flex',
                    transform: 'scale(1.15)'
                }}
            >
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            position: 'absolute',
                            left: '20px',
                            top: '18px'
                        }}
                    >
                        <Image
                            src="/features/inbox_emoji.png"
                            width={50}
                            height={67}
                        />
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'column',
                            top: '26px',
                            left: '80px'
                        }}
                    >
                        <p
                            style={{
                                fontWeight: '700',
                                fontSize: '17px',
                                color: '#FFF'
                            }}
                        >
                            Tasks today
                        </p>
                        <div
                            style={{
                                background: '#262A2D',
                                borderRadius: '4px',
                                padding: '2px 10px',
                                width: '76px',
                                letterSpacing: '0.30px',
                                fontWeight: '500',
                                fontSize: '12px',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <p
                                style={{
                                    marginRight: '2px',
                                    fontWeight: '600',
                                    color: '#AEB5BF'
                                }}
                            >
                                0 sites
                            </p>
                            <Chevron darkmode={true} />
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                top: '78px',
                                left: '-54px',
                                width: '244px',
                                height: '34px',
                                display: 'flex',
                                alignItems: 'center'
                                //paddingTop: "8px",
                                //paddingBottom: "10px",
                            }}
                        >
                            <Image
                                src={'/features/checkdone.png'}
                                height={'34'}
                                width={'31'}
                                alt="Done checkmark"
                            />
                            <div
                                style={{
                                    background: '#35393C',
                                    borderRadius: '5px',
                                    paddingLeft: '10px',
                                    width: '213px',
                                    height: '34px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        lineHeight: '19px',
                                        letterSpacing: '0.24px',
                                        textDecoration: 'line-through',
                                        opacity: '0.6',
                                        color: '#FFF'
                                    }}
                                >
                                    Read all notes
                                </p>
                            </div>
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                top: '128px',
                                left: '-54px',
                                width: '244px',
                                height: '34px',
                                display: 'flex',
                                alignItems: 'center'
                                //paddingTop: "8px",
                                //paddingBottom: "10px",
                            }}
                        >
                            <Image
                                src={'/features/checkdone.png'}
                                height={'34'}
                                width={'31'}
                                alt="Done checkmark"
                            />
                            <div
                                style={{
                                    background: '#35393C',
                                    borderRadius: '5px',
                                    paddingLeft: '10px',
                                    width: '213px',
                                    height: '34px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        lineHeight: '19px',
                                        letterSpacing: '0.24px',
                                        textDecoration: 'line-through',
                                        opacity: '0.6',
                                        color: '#FFF'
                                    }}
                                >
                                    Save all sites
                                </p>
                            </div>
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                top: '178px',
                                left: '-54px',
                                width: '244px',
                                height: '34px',
                                display: 'flex',
                                alignItems: 'center'
                                //paddingTop: "8px",
                                //paddingBottom: "10px",
                            }}
                        >
                            {tickBox ? (
                                <motion.div
                                    //animate={{ scale: checkBoxScale }}
                                    style={{
                                        transform: checkBoxScale,
                                        height: '20px',
                                        width: '20px',
                                        border: checkBoxColor,
                                        //boxShadow: "0 0 0 2px #696a74",
                                        borderRadius: '5px',
                                        marginRight: '11px'
                                    }}
                                />
                            ) : (
                                <Image
                                    src={'/features/checkdone.png'}
                                    height={'34'}
                                    width={'31'}
                                    alt="Done checkmark"
                                />
                            )}
                            <div
                                style={{
                                    background: '#35393C',
                                    borderRadius: '5px',
                                    paddingLeft: '10px',
                                    width: '213px',
                                    height: '34px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        lineHeight: '19px',
                                        letterSpacing: '0.24px',
                                        color: '#FFF',
                                        opacity: tickBox ? '' : '0.6',
                                        textDecoration: tickBox
                                            ? ''
                                            : 'line-through'
                                    }}
                                >
                                    Pick up the groceries
                                </p>
                            </div>
                        </div>
                    </div>
                    <motion.div
                        style={{
                            top: '221px',
                            left: '32px',
                            position: 'absolute',
                            y: pointerY
                        }}
                    >
                        <Image
                            src="/features/pointing.png"
                            height={24}
                            width={24}
                        />
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

function LandingFeatures(props) {
    const { scrollYProgress } = useViewportScroll();
    const [isMobileViewport, setIsMobileViewport] = useState(false);
    React.useEffect(() => {
        //console.log("window.offsetHeight", window.document.body.offsetHeight);
        let height = window.document.body.offsetHeight;
        if (height > 9000) {
            setIsMobileViewport(true);
        }
    }, []);
    const cloudX = isMobileViewport ? 600 : 100;
    const bigCloud = useTransform(scrollYProgress, [0.4, 0.9], [-100, cloudX]);

    return (
        <>
            <div className="container pb-0">
                <div className="columns landingFeatureContainer">
                    <div className="column">
                        <h2 className="featureh2">Reduce your distractions</h2>
                        <p
                            className="featureP"
                            style={{ marginTop: '2rem', paddingRight: '3rem' }}
                        >
                            Stop killing your focus and productivity by jumping
                            tab to tab in your browser.
                        </p>
                        <p
                            className="featureP"
                            style={{
                                marginTop: '1.275rem',
                                paddingRight: '4rem',
                                marginBottom: '2rem'
                            }}
                        >
                            Save tabs that you don’t need at the moment with
                            drag and drop or just one click. Open again with
                            another.{' '}
                        </p>
                        <p
                            className="featureP"
                            style={{
                                marginTop: '1.275rem',
                                paddingRight: '4rem',
                                marginBottom: '2rem'
                            }}
                        >
                            Designed to be intuitive and minimal yet powerful,
                            fast, and reliable. Shift between browser sessions
                            with ease.
                        </p>
                        <Avatar
                            quote={
                                'By far the best solution I found for saving tabs in a practical, aesthetical, and fluid way.'
                            }
                            name={'Nuno Palha'}
                            url={'https://www.strawland.org/'}
                            title={'Founder, strawland.org'}
                            pic={'/features/nuno.png'}
                        />
                    </div>
                    <div
                        className="column"
                        style={{ display: 'flex', alignItem: 'center' }}
                    >
                        <FeatureCards
                            scrollYProgress={scrollYProgress}
                            isMobileViewport={isMobileViewport}
                        />
                    </div>
                </div>
            </div>
            <div className="container pb-0">
                <div className="columns landingFeatureContainerReverse">
                    <div
                        className="column"
                        style={{ display: 'flex', alignItem: 'center' }}
                    >
                        <FeatureNotes
                            scrollYProgress={scrollYProgress}
                            isMobileViewport={isMobileViewport}
                        />
                    </div>
                    <div className="column">
                        <h2 className="featureh2">
                            Quickly jot down ideas and plans
                        </h2>
                        <p
                            className="featureP"
                            style={{ marginTop: '2rem', paddingRight: '3rem' }}
                        >
                            Don’t lose your train of thought while waiting for
                            another app to load.
                        </p>
                        <p
                            className="featureP"
                            style={{
                                marginTop: '1.275rem',
                                paddingRight: '4rem',
                                marginBottom: '2rem'
                            }}
                        >
                            Just open a new tab and start typing notes and
                            to-do’s, combine them with your saved tabs or move
                            them into groups and categories as you see fit.
                        </p>
                        <p
                            className="featureP"
                            style={{
                                marginTop: '1.275rem',
                                paddingRight: '4rem',
                                marginBottom: '2rem'
                            }}
                        >
                            Never forget a site or note again by setting up
                            reminders and create a orderly schedule.
                        </p>
                        <Avatar
                            quote={
                                'I’m using this as a full-on project management tool, and it saves me hours every week.'
                            }
                            name={'Heather Jo Flores'}
                            title={'Author/Teacher, ecodesignhive.com'}
                            url={'ecodesignhive.com'}
                            pic={'/features/heatherj.png'}
                        />
                    </div>
                </div>
            </div>
            <div
                className="SectionComponent hero is-relative p-0"
                style={{
                    background: '#15171B',
                    marginLeft: '-1rem',
                    marginRight: '-1rem'
                }}
            >
                <div className="container pb-0">
                    <div className="columns landingFeatureContainer">
                        <div className="column">
                            <h2 className="featureh2 isFFF">
                                Secure, share, and reach your data anywhere
                            </h2>
                            <p
                                className="featureP"
                                style={{
                                    marginTop: '2rem',
                                    paddingRight: '3rem',
                                    marginBottom: '2rem',
                                    color: '#DFEDFF'
                                }}
                            >
                                Your workspaces are auto-synced to the cloud, so
                                you always have access to your data on different
                                devices. Invite friends/colleagues for real-time
                                collaboration.
                            </p>
                            <p
                                className="featureP"
                                style={{
                                    marginTop: '1.275rem',
                                    paddingRight: '3rem',
                                    marginBottom: '2rem',
                                    color: '#DFEDFF'
                                }}
                            >
                                Reliability at its best: End-to-end encrypted
                                data storage and twice-a-day backups ensure you
                                never loose sleep over lost data.
                            </p>
                            <Avatar
                                quote={
                                    "If you're used to switching between devices & browsers all the time, tabExtend makes your work so much easier"
                                }
                                name={'Blendor Sefaj'}
                                title={'Co-founder, cloverlabs.io'}
                                url={'https://cloverlabs.io/'}
                                pic={'/features/blendor.png'}
                                blue={true}
                            />
                            {/* <motion.div
                style={{
                  x: bigCloud,
                  scale: 1.55,
                  position: "absolute",
                  top: "calc(100% - 84px)",
                  left: "-3%",
                  zIndex: "101",
                }}
              >
                <Image src={"/features/cloud.png"} width={168} height={126} alt="upload to cloud" />
              </motion.div> */}
                        </div>
                        <div
                            className="column"
                            style={{ display: 'flex', alignItem: 'center' }}
                        >
                            <FeatureCloud
                                scrollYProgress={scrollYProgress}
                                isMobileViewport={isMobileViewport}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Testamonials />
        </>
    );
}

export default LandingFeatures;
