import React from 'react';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { Box } from '@chakra-ui/react';

const displayBaseUrl = (url) => {
    let pathArray = url ? url.split('/') : '-';
    let protocol = pathArray[0];
    let host = pathArray[2];
    let baseUrl = protocol + '//' + host;
    let formatted = baseUrl.replace(/(^\w+:|^)\/\//, '');
    return formatted;
};

function ItemSite({ tab, isCompact, withNote }) {
    let favIcon = tab.favIcon?.includes('favicon/size/16@3x')
        ? 'http://www.google.com/s2/favicons?domain=' + tab.url
        : tab.url?.includes('file:///')
        ? '/localFavIcons/fileIcon.png'
        : tab.favIcon === 'localIcon'
        ? '/localFavIcons/' + tab.title.toLowerCase() + '.png'
        : tab.favIcon;

    return (
        <>
            <Box
                cursor="pointer"
                //_hover={{ transform: "translateY(-1px)" }}
                animation="0.18s cubic-bezier(0.22,0.61,0.36,1)"
                role="group"
            >
                <Link
                    href={tab.url ? tab.url : '/'}
                    target="_blank"
                    alt={tab.title}
                >
                    <div
                        style={{
                            display: 'flex',
                            marginBottom: withNote
                                ? '2px'
                                : isCompact
                                ? '6px'
                                : '4px',
                            padding: isCompact ? '2px 0px 4px' : '6px 0px 8px',
                            borderRadius: '6px',
                            marginTop: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            transition: '0.20s cubic-bezier(0.22,0.61,0.36,1)'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                borderRadius: '8px',
                                minWidth: '32px',
                                height: '32px',
                                backgroundColor: '#FFF',
                                boxShadow:
                                    '0 1px 4px -1px rgba(78,80,81,0.4),0 1px 2px 0px rgba(78,80,81,0.22)',
                                margin: '6px 16px 6px 4px'
                            }}
                        >
                            <img
                                src={favIcon ? favIcon : '/fallBackSvg.svg'}
                                size="22px"
                                style={{
                                    height: '22px',
                                    width: '22px',
                                    margin: 'auto',
                                    borderRadius: '2px'
                                }}
                                //transform={loading ? "scale(0.5)" : ""}
                                //opacity={loading ? "1" : "1"}
                                //transition={loading ? "0.22s cubic-bezier(0,1.03,.82,.65)" : ""}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                marginRight: '16px',
                                flexDirection: 'column',
                                maxWidth: '84%'
                            }}
                        >
                            <p
                                style={{
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    marginBottom: '0px',
                                    letterSpacing: '0.42px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '100%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    color: '#04184a'
                                }}
                            >
                                {tab.title}
                            </p>
                            {!isCompact && (
                                <Box
                                    _groupHover={{
                                        textDecoration: 'underline'
                                    }}
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        width: '100%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        fontSize: '13px',
                                        color: '#646F7D'
                                    }}
                                >
                                    {displayBaseUrl(tab.url)}
                                </Box>
                            )}
                        </div>
                    </div>
                </Link>
            </Box>
        </>
    );
}

export default ItemSite;
